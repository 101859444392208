export interface Input {
  username: string;
  route: string;
}
$ const links = [
  {
    href: "/",
    text: "Home",
  },
  {
    href: "/editor",
    text: "Editor",
  },
];
class {
  declare state: {
    mobileMenuOpen: boolean;
  };

  onCreate() {
    this.state = {
      mobileMenuOpen: false,
    };
  }
  toggleNavbar() {
    this.state.mobileMenuOpen = !this.state.mobileMenuOpen;
  }
}

<nav class="navbar mb-3" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a
      class="navbar-item text-gradient is-size-4 has-text-weight-semibold"
      href="/"
    >
      Pairblock
    </a>

    <a
      on-click("toggleNavbar")
      role="button"
      class=`navbar-burger ${state.mobileMenuOpen ? "is-active" : ""}`
      aria-label="menu"
      aria-expanded="false"
      data-target="navbarBasicExample"
    >
      <span aria-hidden="true"/>
      <span aria-hidden="true"/>
      <span aria-hidden="true"/>
      <span aria-hidden="true"/>
    </a>
  </div>

  <div
    id="navbarBasicExample"
    class=`navbar-menu ${state.mobileMenuOpen ? "is-active" : ""}`
  >
    <div class="navbar-start">
      <for|link| of=links>
        <a
          class=`navbar-item ${input.route === link.href ? "has-text-weight-bold" : ""}`
          href=link.href
        >
          ${link.text}
        </a>
      </for>
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <if(!input.username)>
            <sign-in-with-github-button/>
          </if>
          <else>
            <div class="dropdown is-hoverable">
              <div class="dropdown-trigger">
                <button
                  class="button"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                >
                  <span>${input.username}</span>
                  <span class="icon is-small">
                    <i class="fas fa-angle-down" aria-hidden="true"/>
                  </span>
                </button>
              </div>
              <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <a href="/logout" class="dropdown-item">
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </else>
        </div>
      </div>
    </div>
  </div>
</nav>
