import PocketBase from "pocketbase";
import { fetchConfig, type Config } from "./config";

let pb: PocketBase | null = null;

export async function getPocketbase(
  config: Config | null = null
): Promise<PocketBase> {
  if (pb) {
    return pb;
  }
  if (!config) {
    config = await fetchConfig();
  }
  pb = new PocketBase(config.APP.POCKETBASE_URL);
  pb.autoCancellation(false);
  return pb;
}
