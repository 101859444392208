export function getConfig() {
  return {
    APP: {
      POCKETBASE_URL: __APP_POCKETBASE_URL,
    },
  };
}

export type Config = ReturnType<typeof getConfig>;

let config: Config | null = null;

export async function fetchConfig(): Promise<Config> {
  if (config) {
    return config;
  }
  const configResponse = await fetch(
    `${window.location.origin}/api/config`
  ).then((r) => r.json());
  config = configResponse;
  return config as Config;
}
